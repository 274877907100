<template>
    <transition name="cta" appear>
        <div
            v-if="show && document.data.redirect_enabled"
            class="cta"
        >
            <div class="cta-inner mobile-view">
                <PrismicImage
                    :img="document.data.card_image"
                    w="100"
                    h="auto"
                />
                <div class="container">
                    <span>{{ document.data.title }}</span>
                    <Stars
                        v-if="document.data.core_api.ratings?.average_score"
                        :rating="Math.round(document.data.core_api.ratings.average_score)"
                    />
                    <div
                        v-if="$isNorway() && document.data.redirect_enabled"
                        class="text-subtitle"
                    >
                        {{ $getApplySecurelyString(document) }}
                    </div>
                </div>
            </div>
            <div class="cta-inner cta-inner-btn">
                <ButtonTarget
                    size="md"
                    background="green"
                    class="flex p"
                    :link="document"
                    placement="sticky"
                    :text="$translate('cta_credit_card_list', 'Till ansökan')"
                    icon="fas fa-lock white"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import Stars from '@/components/stars/Stars.vue';
export default {
    components: { Stars },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            show: false
        };
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scroll);
    },
    beforeMount() {
        window.addEventListener('scroll', this.scroll);
    },
    methods: {
        scroll(){
            if (window.scrollY >= 800) {
                this.show = true;
            }
            else this.show = false;
        },
    }
};
</script>

<style lang="scss" scoped>
    .cta {
        width: 100%;
        background: $white;
        @include spacing(padding, 2);
        position: fixed;
        bottom: 0;
        z-index: 99;
        box-shadow: 0 14px 16px 0 rgba(0,0,0,0.1);
        box-shadow: $bxs;
        @include flex;

        &-inner {

            &:first-child {
                @include flex;
                margin-right: 40px;

                i { font-size: 18px; }

                .container {
                    @include device(mobile) {
                        display: none;
                    }

                    flex: 1;
                    @include grid(1, 10px);
                    margin-left: 20px;

                    span {
                        font-family: $fontHeader;
                        font-size: 18px;
                    }

                    i { font-size: 18px; }
                }
            }

            &:last-child {
                @include grid(1, 5px);
            }
        }
    }
    .cta {
        @include device(mobile) {
            padding: 0 !important;
        }
    }
    .cta-inner.mobile-view {
        @include device(mobile) {
            display: none !important;
        }
    }
    .cta-inner-btn {
        @include device(mobile) {
            width: 100%;
        }
    }
    .cta-enter-active {
        transition: all .2s ease-in-out;
    }
    .cta-leave-active {
        transition: all .2s ease-in-out;
    }
    .cta-enter, .cta-leave-to {
        transform: translateY(100px);
        // opacity: 0;
    }
</style>
