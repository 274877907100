<template>
    <table class="table">
        <tbody>
            <tr 
                v-for="(row, rowIndex) in tableData" 
                :key="rowIndex"
            >
                <th>{{ row.title }}</th>
                <td>{{ row.value }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
            required: true
        }
    },
};
</script>
<style lang="scss" scoped>
    .table {
        margin-top: 1rem;
        flex-grow: 1;
        box-shadow: $btnShadow;
        @include border;
        flex-grow: 1;
        border-collapse: collapse;
        tr {
            @include border(bottom);
            &:nth-child(even) {
                background-color: $lightgray;
            }
            td {
                font-family: $fontText;
                @include border(left);
            }
            th {
                font-family: $fontHeader;
            }
            td, th {
                text-align: left;
                padding: 1rem 0.8rem;
                vertical-align: middle;
                width: 50%;
                @include device(pad) {
                    padding: 1rem 1.5rem;
                }
            }
        }
    }
</style>